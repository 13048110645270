:root{
  --teal:#27B1A1;
  --midnight_blue:#030322;
  --neon_mint: #3BFF96;
  --lt-neon_mint: #adf6cf;
  --pale_lavendar: #DEDCFF;
  --pale-border: var(--pale_lavendar) 2px solid;
}


/* App.js */
::-webkit-scrollbar {
  width: auto;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--midnight_blue);
  border: var(--pale_lavendar);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--teal);
  border-radius: 50px;
  border: var(--pale-border);
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* body ----- */
*{
  margin:0px;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior:smooth;
}

body{
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: white !important;
}

/* Navbar -------- */
.navbar-brand {
  color: #fff;
  font-size: 1.5em;
  font-weight: 600;
}

nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  background-color:#030322de !important;
  transition: all 3s ease-in-out;
  height: auto;
  border-bottom: var(--pale-border);
  z-index: 9999;
}
.nav-link {
  color: #ffffff !important;
  transition: all 1s ease-in-out;
  font-weight: 600;
}
.nav-link:hover {
  color: #1fd379!important;
  font-weight: 600;
  transition: all 1s ease-in-out;
}
.social-icon-container i {
  margin: 5px;
}

h1{
  color: var(--pale_lavendar)!important;
}

/*Banner.js ------ */
.banner{
  height: 100vh;
  margin-top: 0px;
  padding:260px 0 100px 0;
  background: linear-gradient(135deg, #6ec57530 0, #030322 100%), url("../src/assets/img/banner/tech-abstract-bg.png") no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color:rgb(255, 255, 255);
  text-align: left;
  border-bottom: var(--pale-border);
}

.banner-img{
  z-index: 999;
  position: absolute;
  animation: moveMouse 10s ease-in-out infinite ;
  animation-direction: alternate-reverse;
}

.tagline{
  color: rgb(239, 241, 239);
  padding: 5px 10px;
  background: linear-gradient(var(--teal), #3bff9691);
  font-weight: 600;
  border: var(--pale-border);
  border-radius: 2px;}

.name{
  color:rgb(255, 255, 255);
  font-size: 50px;
}




/* Skills.js */

.item img{
  border-radius: 100%;
  width: 200px;
  height: 200px;
}
.skill{
  position: relative;
  margin-top: 0;
  padding:0px 0 50px 0;
   background-image: url("../src/assets/img/skills/skills-bg.png"); 
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.skill-box{
  background-color: var(--midnight_blue);
  padding: 60px 50px;
  text-align: center;
  border-radius: 64px;
  margin-top:-60px;
  border: var(--pale-border);
}

/* Projects.js ------- */
#projects{
  background: var(--midnight_blue);
}
.projects{
  padding: 60px 50px;
  text-align: justify;
  border-top: var(--pale-border);
  background-color: #9961fb;
}

.proj-btn{
  height: 40px;
  width: 120px;
  border-radius: 2px;
  font-weight: 600;
  background-color: var(--pale_lavendar);
  color:var(--midnight_blue);
  text-decoration: none;
  padding: 5px 30px;
  box-shadow: inset 0px -7px 0px 0px rgba(20, 95, 56, 0.41);
  transition: all .05s;
  margin: 3px;
}
 
.proj-btn :hover{
transition: all 1s;


}

 #proj-code{
  background-color: #27B1A1;
 }

 .proj-img{
  width: 400px;
  height: 350px;
  box-shadow: 0px 10px 5px gray;
  border: 1px solid black;
  border-radius: 30px;
 }

 #proj-launch{
  background-color: var(--neon_mint)!important;
 }

.project-btn-bx{
 padding: 15px;
}


/* Contacts.js ------- */
.contact{
  padding: 60px 50px;
  height: auto;
  background: linear-gradient(#030322, var(--teal));
  ;
}
.contact-img{
  width: 80px;
  animation: rotateCube 5s infinite;
  animation-direction: alternate-reverse;
}
.contact i{
  width: 100px !important;
  height: 200px!important;
  font-size: 50px;
  color:var(--pale_lavendar) !important;
  
}

/* Footer */
.footer{
  background-color: var(--teal);
  padding: 10px;
}

/* Buttons ---------*/

.standard-btn{
 height: auto;
 width: auto;
  border-radius: 2px;
  background-color: var(--pale_lavendar);
  color:var(--midnight_blue);
  text-decoration: none;
  padding: 5px 30px;
  box-shadow: inset 0px -7px 0px 0px rgba(20, 95, 56, 0.41);
  transition: all .05s;
  font-size: 16px!important;
}
.standard-btn:hover{
  color:#030322;
  width: 125px;
  box-shadow: inset 0px -5px 0px 0px rgba(20, 95, 56, 0.41);
  transition: all .05s;
}

.banner-btn{
  background-color: rgba(245, 222, 179, 0.034);
  color: white;
  font-weight: 600;
  border: none;
  text-decoration: none;
}



/* Animations ------*/
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes moveMouse {
  0%{
    transform: translateX(300px);
    transform: rotate(45deg);
  }
  25%{
    transform: translateX(300px);
    transform: rotate(-15deg);
  }
  
  50%{
   
  }

}

@keyframes rotateCube {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 500px) {
  

  .banner-img{
   position: absolute;
   top:120px;
   left:50%;
  }

  .skill-box{
    background-color: var(--midnight_blue);
    padding: 60px 50px;
    text-align: center;
    border-radius: 64px;
    margin-top:-60px;
    border: var(--pale-border);
  }

  .banner{
    height: auto;
  }

  .projects{
    padding: 5px;
  }

  .contact{
    height: auto;
  }
}

@media screen and (max-width: 800px) {
  
  .banner-img{
     position: absolute;
     top:120px;
     left:50%;
     width: 200px;
    }
  .projects{
    padding: 10px;
  }
  .contact{
    padding: 10px;
  }
}